// ###############################################
// ###############################################
// START Boilerplate (not Important for the Tutorial)

$prime: #FF6E48;
$second: #0C1016;

// END Boilerplate (not Important for the Tutorial)
// ###############################################
// ###############################################
