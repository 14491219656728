// ###############################################
// ###############################################
// START Boilerplate (not Important for the Tutorial)

@import 'var';

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
    width: 100%;
    height: 100%;
    background-color: $second;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0; }

d {}// END Boilerplate (not Important for the Tutorial)
// ###############################################
// ###############################################
